var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "22",
        viewBox: "0 0 24 22",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("line", {
        attrs: {
          x1: "3",
          y1: "2",
          x2: "21",
          y2: "2",
          stroke: "#008EBE",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M2.62964 11.2H21.3704",
          stroke: "#008EBE",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("line", {
        attrs: {
          x1: "3",
          y1: "20",
          x2: "21",
          y2: "20",
          stroke: "#008EBE",
          "stroke-width": "4",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }